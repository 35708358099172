import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

import { Typography, Toolbar, AppBar } from '@mui/material';

export default function Header(props) {
    return (
        <></>
    );
}